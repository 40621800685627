<template>
    <nav :class="{ 'navbar-searching': searching }"
         class="navbar navbar-expand bg-white text-dark navbar-light fixed-top shadow-sm px-4 py-1">
        <button aria-controls="top-navigation"
                aria-expanded="false"
                aria-label="Toggle navigation"
                class="navbar-toggler d-block d-md-none"
                type="button"
                @click="$store.commit('sidebar/openSidebar', '100%')">
            <span class="navbar-toggler-icon"></span>
        </button>

        <ol v-if="breadcrumbs.length > 0" class="breadcrumb mb-0">
            <li v-for="(breadcrumb, i) in breadcrumbs" :key="'breadcrumb-item-' + i" class="breadcrumb-item">
                <span v-if="!Object.prototype.hasOwnProperty.call(breadcrumb, 'to')"
                      :key="'breadcrumb-' + i">
                    {{ breadcrumb.name }}
                </span>
                <router-link v-else :key="'breadcrumb-' + i" :to="{ name: breadcrumb.to }">
                    {{ breadcrumb.name }}
                </router-link>
            </li>
        </ol>

        <div id="top-navigation" class="collapse navbar-collapse justify-content-end">
            <ul class="navbar-nav ms-2">
                <li v-if="user !== null" class="nav-item dropdown ms-2">
                    <a aria-expanded="false"
                       aria-haspopup="true"
                       class="nav-link dropdown-toggle p-0 d-inline-flex align-items-center h-100"
                       data-bs-toggle="dropdown"
                       role="button">
                        <v-gravatar id="user-avatar" :email="user.email" :size="24" class="me-2"/>
                        <span class="font-weight-bold text-dark d-none d-md-block">
                            {{ user.firstName }} {{ user.lastName }}
                        </span>
                    </a>
                    <div class="dropdown-menu position-right">
                        <router-link :to="{ name: 'my-profile' }" class="dropdown-item">
                            {{ $tc('my-profile') }}
                        </router-link>
                        <div class="dropdown-divider"></div>
                        <button class="dropdown-item" @click="onSignOut">{{ $t('actions.sign-out') }}</button>
                    </div>
                </li>
                <li class="nav-item dropdown ms-2">
                    <a aria-expanded="false"
                       aria-haspopup="true"
                       class="nav-link text-dark position-relative"
                       data-bs-toggle="dropdown"
                       role="button">
                        <font-awesome-icon :icon="['fas', 'language']" size="lg"/>
                    </a>
                    <div class="dropdown-menu position-right">
                        <button class="dropdown-item" @click="setLocale('de-DE')">Deutsch</button>
                        <button class="dropdown-item" @click="setLocale('en-US')">English (US)</button>
                    </div>
                </li>
                <li class="nav-item dropdown ms-2">
                    <component-notification/>
                </li>
                <li class="nav-item dropdown ms-2">
                    <a aria-expanded="false"
                       aria-haspopup="false"
                       class="nav-link text-dark position-relative"
                       data-bs-toggle="dropdown"
                       role="button">
                        <font-awesome-icon :icon="['far', 'question-circle']" size="lg"/>
                    </a>
                    <div class="dropdown-menu position-right">
                        <a class="dropdown-item"
                           href="https://www.spotemotion.com/get-started/"
                           rel="noopener noreferrer"
                           target="_blank">
                            {{ $t('actions.get-started') }}
                            <font-awesome-icon class="float-end" icon="external-link-alt"/>
                        </a>
                        <a class="dropdown-item"
                           href="https://www.spotemotion.com/faq/"
                           rel="noopener noreferrer"
                           target="_blank">
                            {{ $t('faq') }}
                            <font-awesome-icon class="float-end" icon="external-link-alt"/>
                        </a>
                        <a class="dropdown-item"
                           href="https://www.spotemotion.com/features/"
                           rel="noopener noreferrer"
                           target="_blank">
                            {{ $tc('feature', 2) }}
                            <font-awesome-icon class="float-end" icon="external-link-alt"/>
                        </a>
                        <a class="dropdown-item"
                           href="https://www.spotemotion.com/support/"
                           rel="noopener noreferrer"
                           target="_blank">
                            {{ $t('support') }}
                            <font-awesome-icon class="float-end" icon="external-link-alt"/>
                        </a>
                        <hr class="dropdown-divider">
                        <a class="dropdown-item"
                           href="https://www.spotemotion.com/changelog/"
                           rel="noopener noreferrer"
                           target="_blank">
                            {{ $tc('changelog', 1) }}
                            <font-awesome-icon class="float-end" icon="external-link-alt"/>
                        </a>
                        <button class="dropdown-item"
                                data-bs-target="#about"
                                data-bs-toggle="modal"
                                role="button">
                            {{ $t('about') }}
                        </button>
                    </div>
                </li>
            </ul>
        </div>
    </nav>
</template>

<script>
import ComponentNotification from './ComponentNotification.vue';
import setCookie from "@/lib/set-cookie";

export default {
    components: {ComponentNotification},
    name: "ComponentNavbar",
    data() {
        return {
            searching: false,
            term: ''
        }
    },
    computed: {
        appTitle() {
            return process.env.VUE_APP_TITLE;
        },
        user() {
            return this.$store.state.USER.user;
        },
        breadcrumbs() {
            return this.$route.meta.breadcrumb;
        },
    },
    methods: {
        onSignOut() {
            this.$store.dispatch('USER/LOGOUT');
            this.$router.push({name: 'sign-in'});
            this.$gtag.event('USER_LOGOUT');
        },
        setLocale(locale) {
            this.$i18n.locale = locale;
            setCookie('locale', locale);
            this.$forceUpdate();
        }
    },
    beforeMount() {
        this.version = process.env.VUE_APP_VERSION;
    }
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/variables";

nav {
    border-bottom: 2px solid transparent;
    -webkit-transition: border-bottom-color .3s;
    -moz-transition: border-bottom-color .3s;
    -ms-transition: border-bottom-color .3s;
    -o-transition: border-bottom-color .3s;
    transition: border-bottom-color .3s;

    &.navbar-searching {
        border-bottom-color: $primary;
    }
}

.position-right {
    left: unset;
    right: 0;
}

@media only screen and (max-width: 768px) {
    nav {

        &.navbar-searching {
            border-bottom: 2px solid transparent;
        }

        #search-bar {
            position: absolute;
            border-bottom: 2px solid transparent;
            top: 50px;
            left: 0;
            border-bottom: 2px solid transparent;
            -webkit-transition: border-bottom-color .3s;
            -moz-transition: border-bottom-color .3s;
            -ms-transition: border-bottom-color .3s;
            -o-transition: border-bottom-color .3s;
            transition: border-bottom-color .3s;
            box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;

            &.navbar-searching {
                border-bottom-color: $primary;
            }
        }
    }
}

</style>
