<template>
    <component-modal id="about" :center="false" :title="$t('about')">
        <div class="modal-body">
            <strong>EUSDP Framework:</strong> v1.0.0<br>
            <strong>Spot Emotion - Service:</strong> v1.0.8<br>
            <strong>Spot Emotion - WebApp:</strong> {{ webAppVersion }}<br>
        </div>
        <div class="modal-footer justify-content-start">
            <p class="text-muted small">{{ $t('copyright-text') }}</p>
        </div>
    </component-modal>
</template>

<script>
import ComponentModal from "@/components/ui/ComponentModal";

export default {
    name: "ComponentAbout",
    components: {ComponentModal},
    computed: {
        frameworkVersion() {
            return 'v1.0.0';
        },
        serviceVersion() {
            return 'v' + process.env.VUE_APP_VERSION_SERVICE;
        },
        webAppVersion() {
            return 'v' + process.env.VUE_APP_VERSION_WEBAPP;
        }
    }
}
</script>

<style scoped>

</style>