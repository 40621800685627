<template>
    <div v-if="promotions.length > 0" class="row">
        <div class="col-12 mb-3">
            <div v-for="(promotion, i) in promotions"
                 :key="'promotion-1' + i"
                 class="card promotion me-1 mb-3">
                <div class="card-body">
                    <p class="card-text mb-2">{{ promotion.description }}</p>
                    <div class="promotion-inner">
                        <h6 class="card-title my-1 font-weight-bold">{{ promotion.title }}</h6>
                        <small class="card-text d-block mt-1 mb-2 font-weight-bold">{{ promotion.details }}</small>
                    </div>
                    <a :href="promotion.url" class="btn btn-sm btn-primary me-2">{{ $tc('detail', 2) }}</a>
                    <button class="btn btn-sm btn-light" @click="$store.commit('promotion/removePromotion', i)">
                        {{ $t('actions.hide') }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ComponentPromotion",
    computed: {
        promotions: function () {
            return this.$store.state.promotion.promotions
        }
    },
    async beforeCreate() {
        // dispatch get promotions action
    }
}
</script>

<style lang="scss" scoped>
.promotion {
    background-color: #f9f9f9;
}

.promotion-inner {
    max-width: 320px;
}

@media only screen and (max-width: 768px) {
    .promotion-inner {
        max-width: 100%;
    }
}
</style>