<template>
    <nav>
        <div class='collapse-sidebar-horizontally'>
            <div class="collapse-sidebar-inner" @click="$store.commit('sidebar/toggleSidebar')">
                <font-awesome-icon :icon="sidebarCollapsed ? 'chevron-left' : 'chevron-right'"
                                   class="collapse-sidebar-arrow"
                                   size="lg"/>
            </div>
        </div>
        <div id="icon-container" class="d-flex justify-content-between">
            <router-link to="/">
                <img :alt="appTitle"
                     :class="{'p-3' : $store.state.sidebar.width !== '0px'}"
                     :src="sidebarCollapsed ? require('@/assets/img/logo.png') : require('@/assets/img/logo-sm.jpg')"
                     class="d-block mw-100">
            </router-link>
            <font-awesome-icon class="text-white m-2 d-block d-md-none cursor-pointer"
                               icon="times"
                               size="lg"
                               @click="$store.commit('sidebar/closeSidebar', '0px')"/>
        </div>

        <ul ref="sidebar" class="nav flex-column sidebar">
            <template v-for="(content, i) in contents">
                <li :key="'nav-group-' + i" class="sidebar-heading nav-item">
                    <a :aria-expanded="!contentCollapsed[content.identifier]"
                       :class="getSidebarHeadingClasses()"
                       :title="content.name"
                       class="btn d-block nav-item rounded-0"
                       role="button"
                       @click="$store.commit('sidebar/toggleGroup', content.identifier)">
                        <span :class="{ hide: !sidebarCollapsed }" class="group-label">
                            {{ content.name }}
                        </span>
                        <font-awesome-icon :icon="contentCollapsed[content.identifier] ? 'chevron-down' : 'chevron-up'"
                                           :pull="sidebarCollapsed ? 'right' : undefined"
                                           :size="sidebarCollapsed ? '1x' : 'lg'"/>
                    </a>
                </li>
                <ul v-for="(item, j) in content.items"
                    :key="'nav-group-' + i + '-item-' + j"
                    :class="{ show: !contentCollapsed[content.identifier] }" class="sidebar-submenu nav flex-column collapse">
                    <li :title="item.name" class="nav-item w-100">
                        <router-link :to="{ name: item.route }" active-class="active" class="nav-link text-start">
                            <font-awesome-icon :icon="item.icon" class="me-2"/>
                            <span :class="{ hide: !sidebarCollapsed }" class="group-label">
                                {{ item.name }}
                            </span>
                        </router-link>
                    </li>
                </ul>
            </template>
        </ul>
    </nav>
</template>

<script>
export default {
    name: "ComponentSidebar",
    computed: {
        sidebarCollapsed() {
            return this.$store.state.sidebar.open;
        },
        appTitle() {
            return process.env.VUE_APP_TITLE;
        },
        contents() {
            return this.$store.getters['sidebar/content'];
        },
        contentCollapsed() {
            return this.$store.state.sidebar.collapsed;
        }
    },
    methods: {
        getSidebarHeadingClasses() {
            return {
                'text-start': true
            }
        },
        setSidebarPosition(e) {
            this.$store.commit('sidebar/setPosition', e.target.scrollTop);
        }
    },
    mounted() {
        const sidebar = this.$refs.sidebar;
        sidebar.addEventListener("scroll", this.setSidebarPosition, true);
        sidebar.scrollTop = this.$store.state.sidebar.position;
    },
    beforeDestroy() {
        const sidebar = this.$refs.sidebar;
        sidebar.removeEventListener("scroll", this.setSidebarPosition, true);
    },
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/variables";

.sidebar {
    overflow-y: scroll;
    height: calc(100vh - 71px);
    -ms-overflow-style: none;
    scrollbar-width: none;

    &.collapsed {
        height: calc(100vh - 83px);
    }
}

::-webkit-scrollbar {
    width: 0;
    background: transparent;
}

nav {
    #icon-container {
        position: relative;
        overflow: hidden;
    }

    .cursor-pointer {
        cursor: pointer;
    }

    .collapse-sidebar-horizontally {
        height: 100%;
        width: 15px;
        position: absolute;
        right: -15px;
        display: flex;
        justify-content: center;
        align-items: center;

        .collapse-sidebar-inner {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100px;
            width: 15px;
            background-color: rgba(194, 195, 191, .5);
            transition: height .3s ease;

            &:hover {
                height: 100vh;
                transition: height .3s ease;
                cursor: pointer;
            }
        }
    }

    .nav {
        flex-wrap: nowrap;
    }

    .group-label.hide {
        display: none;
    }

    .sidebar-heading a {
        font-size: 14px;
        color: $light;
    }

    .sidebar-submenu a {
        font-size: 14px;
        color: $light;
        background-color: rgba(255, 255, 255, .1);

        &:hover {
            background-color: $primary;
        }

        &.active {
            background-color: rgba(255, 255, 255, .2);
        }
    }
}

@media only screen and (max-width: 768px) {
    .collapse-sidebar-horizontally {
        display: none !important;
    }
}
</style>