<template>
    <div id="footer" class="d-flex justify-content-between align-items-center px-3 py-1 bg-light">
        <small>
            <a v-for="(link, i) in links"
               :key="'footer-link-' + i"
               :href="link.href"
               class="text-secondary"
               rel="noreferrer noopener"
               target="_blank">
                {{ link.title }}
            </a>
        </small>
        <small class="text-secondary">{{ $t('copyright-text') }}</small>
    </div>
</template>

<script>
import {stinaImprintUrl, stinaPrivacyPolicyUrl, stinaTermsAndConditionsUrl} from "@/lib/links";

export default {
    name: "ComponentFooter",
    data() {
        return {
            links: [
                {
                    title: this.$t('privacy-policy'),
                    href: stinaPrivacyPolicyUrl
                },
                {
                    title: this.$t('imprint'),
                    href: stinaImprintUrl
                },
                {
                    title: this.$t('general-terms-and-conditions'),
                    href: stinaTermsAndConditionsUrl
                }
            ]
        }
    }
}
</script>

<style lang="scss" scoped>
#footer {
    a {
        text-decoration: none;

        &:not(:last-child):after {
            content: "|";
            display: inline-block;
            margin: 0 .5rem;
        }
    }
}
</style>