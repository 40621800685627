<template>
    <div>
        <component-navbar id="navbar" :style="{ left: sidebarWidth }"/>

        <div id="sidebar"
             :class="{ collapsed: !$store.state.sidebar.open }"
             :style="{ width: sidebarWidth}"
             class="shadow-sm bg-dark">
            <component-sidebar/>
        </div>

        <main id="content"
              :style="{ width: 'calc(100% - ' + sidebarWidth + ')', marginLeft: sidebarWidth }">
            <div id="page-content" class="grid text-start px-4 py-3 bg-white">
                <div v-if="title" class="mb-4 d-flex align-items-center">
                    <button v-if="innerSidebar === true"
                            class="btn btn-sm btn-outline-light me-2"
                            type="button"
                            @click="console.log('TODO: Toggle inner sidebar')">
                        <font-awesome-icon class="text-dark" icon="bars"/>
                    </button>
                    <h3 id="page-title" :class="{ 'me-2': status !== null }" class="m-0 fw-bold">{{ title }}</h3>
                    <span v-if="status" :class="statusBg" class="badge">{{ status }}</span>
                </div>
                <component-promotion/>
                <slot/>
            </div>
            <component-toast-container/>
            <component-footer/>
            <component-about/>
        </main>

        <transition name="fade">
            <div v-if="backdrop === true" id="backdrop"></div>
        </transition>
    </div>
</template>

<script>
import ComponentNavbar from "@/components/ui/ComponentNavbar";
import ComponentSidebar from "@/components/ui/ComponentSidebar";
import ComponentPromotion from "@/components/ui/ComponentPromotion";
import ComponentFooter from "@/components/ui/ComponentFooter";
import ComponentToastContainer from "@/components/ui/toast/ComponentToastContainer";
import ComponentAbout from "@/components/ComponentAbout";

export default {
    name: "LayoutFullWidth",
    components: {
        ComponentAbout,
        ComponentSidebar,
        ComponentNavbar,
        ComponentPromotion,
        ComponentFooter,
        ComponentToastContainer
    },
    computed: {
        backdrop() {
            return this.$store.state.backdrop.visible;
        },
        sidebarWidth() {
            return this.$store.state.sidebar.width;
        }
    },
    props: {
        title: {
            type: [String],
            required: false
        },
        status: {
            Type: String,
            required: false,
            default: null
        },
        statusBg: {
            Type: String,
            required: false,
            default: 'bg-light'
        },
        innerSidebar: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            user: null
        }
    },
    created() {
        if (window.innerWidth < 768) {
            this.$store.commit('sidebar/closeSidebar', '0px')
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/variables";

#navbar {
    z-index: 99;
    transition: left .3s ease;
}

#sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    transition: width .3s ease;

    &.collapsed {
        transition: width .3s ease;
    }
}

#title {
    background-color: #f3f3f3;
}

#content {
    margin-top: 45px;
    height: calc(100vh - 45px);
    transition: all .3s ease;
    display: flex;
    flex-direction: column;

    #page-title {
        letter-spacing: -1px;
    }

    #page-content {
        flex-grow: 1;
        -webkit-flex-grow: 1;
    }
}

#backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, .5);
    z-index: 100;
}

.fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
    opacity: 0;
}

@media only screen and (max-width: 768px) {
    #content {
        margin-top: 97px;
        height: calc(100vh - 97px);
    }
}
</style>