<template>
    <div>
        <a aria-expanded="false"
           aria-haspopup="true"
           class="nav-link text-dark position-relative"
           role="button"
           @click="openNotifications">
            <font-awesome-icon :icon="['far', 'bell']" size="lg"/>
            <font-awesome-icon v-if="hasUnreadNotification"
                               class="text-danger navbar-notification-circle"
                               icon="circle"
                               size="sm"/>
        </a>
        <div id="notification" :class="{show: open}" class="dropdown-menu shadow-sm end-0 p-2">
            <div class="mb-2">
                <router-link :to="{ name: 'settings-notifications' }" class="text-decoration-none">
                    <font-awesome-icon icon="cog"/>
                    {{ $tc('setting', 2) }}
                </router-link>
                <button class="btn btn-close float-end" @click="open = false"></button>
            </div>
            <template v-if="notifications.length > 0">
                <div class="d-flex justify-content-between mb-2">
                    <button class="btn btn-primary btn-sm">{{ $t('actions.mark-as-read') }}</button>
                    <div class="d-flex me-2">
                        <div class="btn-group">
                            <button aria-expanded="false"
                                    aria-haspopup="true"
                                    class="btn btn-primary btn-sm"
                                    data-bs-toggle="dropdown">
                                {{ $tc('filter', 1) }}
                            </button>
                            <div aria-labelledby="dropdownMenuButton" class="dropdown-menu">
                                <button :class="notificationFilter === 'unread' ? 'font-weight-bold' : ''"
                                        class="dropdown-item"
                                        @click="notificationFilter = 'unread'">
                                    {{ $t('unread') }}
                                </button>
                                <button :class="notificationFilter === 'critical' ? 'font-weight-bold' : ''"
                                        class="dropdown-item"
                                        @click="notificationFilter = 'critical'">
                                    {{ $t('critical') }}
                                </button>
                                <button :class="notificationFilter === 'favorites' ? 'font-weight-bold' : ''"
                                        class="dropdown-item"
                                        @click="notificationFilter = 'favorites'">
                                    {{ $tc('favorite', 2) }}
                                </button>
                                <div class="dropdown-divider"></div>
                                <button class="dropdown-item" @click="notificationFilter = null">
                                    {{ $tc('clear-filter', 1) }}
                                </button>
                            </div>
                            <form class="d-flex w-100 align-items-center">
                                <label class="sr-only" for="navbar-search">{{ $t('actions.search') }}</label>
                                <input id="navbar-search"
                                       :aria-label="$t('actions.search')"
                                       :placeholder="$t('actions.search') + '...'"
                                       class="form-control ms-2"
                                       type="search">
                            </form>
                        </div>
                    </div>
                </div>
                <div v-for="(notification, i) in notifications"
                     :key="i"
                     class="border-top d-flex justify-content-between p-2">
                    <div :class="notification.critical ? 'text-danger' : 'text-dark'">
                        <h6 class="mb-1 d-flex">
                            <!--
                            <font-awesome-icon
                                icon="circle"
                                size="sm"
                                class="me-1 mt-1 notification-circle"
                                :class="notification.unread ? (notification.critical ? 'text-danger' : 'text-primary') : 'text-muted'"/>
                                -->
                            <strong>{{ notification.title }}</strong>
                        </h6>
                        <p :class="notification.unread ? 'font-weight-bold' : ''" class="mb-0">{{
                                notification.message
                            }}</p>
                    </div>
                    <div class="d-flex w-33 align-items-center justify-content-end">
                        <small class="me-1">{{ notification.date | timestamp }}</small>
                        <button class="bg-transparent border-0">
                            <font-awesome-icon :icon="['far', 'star']"/>
                        </button>
                        <button class="bg-transparent border-0">
                            <font-awesome-icon :icon="['far', 'trash-alt']"/>
                        </button>
                    </div>
                </div>
            </template>
            <div v-else id="no-notifications" class="dropdown-item text-center py-4">
                <font-awesome-icon class="mb-2 text-primary" icon="calendar-check" size="4x"/>
                <p class="mb-1"><strong>{{ $t('no-notifications') }}</strong></p>
                <p>{{ $t('no-notifications-for-you') }}</p>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'ComponentNotification',
    computed: {
        notifications() {
            let notifications = []
            if (this.notificationFilter) {
                this.$store.state.notification.notifications.forEach(notification => {
                    if (notification[this.notificationFilter]) {
                        notifications.push(notification)
                    }
                })
            } else {
                notifications = this.$store.state.notification.notifications
            }
            return notifications
        },
        hasUnreadNotification() {
            let unreadNotification = false;
            this.notifications.forEach(notification => {
                if (notification.unread) {
                    unreadNotification = true;
                }
            })
            return unreadNotification;
        },
    },
    data() {
        return {
            open: false,
            notificationFilter: null,
        }
    },
    methods: {
        openNotifications() {
            this.open = !this.open;
        },
        closeNotifications(event) {
            let filterContainer = document.querySelector('#notification');

            if (!this.open || event.target === filterContainer) {
                return;
            }

            if (!filterContainer.contains(event.target)) {
                this.open = false;
            }
        }
    },
    beforeMount() {
        window.addEventListener('mouseup', this.closeNotifications);
    },
}
</script>
<style lang="scss" scoped>

.w-33 {
    width: 33.33% !important;
}

#notification {
    width: 500px;
    min-height: 100px;
}

#no-notifications:active,
#no-notifications:hover {
    background-color: transparent;
    color: #212529;
}

.notification-circle {
    width: 7px;
    height: 7px;
}

.navbar-notification-circle {
    position: absolute;
    width: 7px;
    height: 7px;
    top: 7px;
    right: 6px;
}
</style>
