<template>
    <div :id="id"
         :aria-labelledby="id + '-label'"
         aria-hidden="true"
         class="modal"
         role="dialog"
         tabindex="-1">
        <div :class="['modal-dialog', size, centered]" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 :id="id + '-label'" class="modal-title">{{ title }}</h5>
                    <button :aria-label="$t('actions.close')" class="btn-close" data-bs-dismiss="modal"
                            type="button"></button>
                </div>
                <slot/>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ComponentModal",
    computed: {
        centered() {
            return {
                'modal-dialog-centered': this.$props.center
            }
        }
    },
    props: {
        id: {
            type: [String, Number],
            default: '',
            required: true
        },
        title: {
            type: String,
            default: '',
            required: true
        },
        size: {
            type: String,
            default: '',
            required: false,
            validator: value => {
                return [
                    'modal-xxl',
                    'modal-xl',
                    'modal-lg',
                    'modal-sm',
                    'modal-fullscreen',
                    'modal-fullscreen-sm-down',
                    'modal-fullscreen-md-down',
                    'modal-fullscreen-lg-down',
                    'modal-fullscreen-xl-down',
                    'modal-fullscreen-xxl-down',
                    ''
                ].indexOf(value) !== -1
            }
        },
        center: {
            type: Boolean,
            default: false,
            required: false
        }
    }
}
</script>

<style scoped>

</style>